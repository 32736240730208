(function () {
  const defaultAttachmentInputs = document.querySelectorAll('.jsAttachmentsInput');
  const ATTACHMENT_TEXT_DEFAULT = 'Attach a file <span class="file-size-desciption">(— max size 25MB)</span>';
  const MAX_FILE_SIZE = 25000000;
  const MAX_FILE_NAME_LENGTH = 20;

  function truncateText(text) {
    if (text.length > MAX_FILE_NAME_LENGTH) {
      const fileExtension = text.split('.').pop();
      const numberOfLastSymbols = 4;
      const lastDotIndex = text.lastIndexOf('.');
      const startIndexOfLastSymbols = lastDotIndex - numberOfLastSymbols;

      const firstSymbols = text.substring(0, MAX_FILE_NAME_LENGTH - numberOfLastSymbols);
      const lastSymbolsBeforeExtension = text.substring(startIndexOfLastSymbols, lastDotIndex);

      return firstSymbols + '...' + lastSymbolsBeforeExtension + '.' + fileExtension;
    }
    return text;
  }

  function showTooltipError() {
    $('#tooltip_error')
      .show(300, function () { $(this).addClass('active') })
      .delay(7000)
      .hide(300, function() { $(this).removeClass('active') });
  }

  defaultAttachmentInputs.forEach(defaultAttachmentInput => {
    const form = defaultAttachmentInput.closest('form')
    const attachmentInput = form.querySelector('.TextareaGroup-attachment');
    const deleteAttachmentButton = form.querySelector('.jsAttachmentsDelete');

    if (defaultAttachmentInput && attachmentInput) {
      defaultAttachmentInput.addEventListener('change', () => {

        if(defaultAttachmentInput.files[0] && defaultAttachmentInput.files[0].size > MAX_FILE_SIZE) {
          showTooltipError();
          defaultAttachmentInput.value = '';

        } else if (defaultAttachmentInput.value) {
          $('#tooltip_error').removeClass('active').hide(300);

          const fileName = defaultAttachmentInput.value.match(/[/\\]([\s\w()\-.]+)$/)[1] // extracts the name of a file (including its extension) from the full path

          attachmentInput.innerHTML = `<span>${truncateText(fileName)}</span>`;
          deleteAttachmentButton.classList.toggle('d-none', false);
        }
      });

      attachmentInput.addEventListener('keydown', e => {
        if (e.which === 13) {
          defaultAttachmentInput.click();
        }
      });
    }

    if(deleteAttachmentButton) {
      deleteAttachmentButton.addEventListener('click', () => {
        defaultAttachmentInput.value = '';
        attachmentInput.innerHTML = ATTACHMENT_TEXT_DEFAULT;
        deleteAttachmentButton.classList.toggle('d-none', true);
      });
    }
  })
})();
